<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <template v-if="customer">
          <h2>{{ customer.name }}</h2>
        </template>
      </div>
    </template>
    <template v-slot:topbar>
      <template v-if="this.$userCan('manage_customers')">
        <div class="flex flex-wrap items-center">
          <Button icon="book-open" @click="viewOrders" tooltip="Klienta pasūtījumi" :tooltipBottom="true" />

          <Button icon="cash-multiple" @click="viewPrices" />

          <Button icon="delete" @click="deleteCustomer(customer.id)" />
        </div>
      </template>
    </template>
    
    <template v-slot:content>
      <div>
        <template v-if="customer">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <CustomerDetails :customer="customer" />

              <AllBranches :branches="customer.branches" />
              <AllCustomerContacts :contacts="customer.contacts" />
              
              <template v-if="customer.orders">
                <AllOrderHistory :data="customer.orders" />
              </template>

              <template v-if="customer.invoices">
                <InvoiceList :customerInvoices="customer.invoices" />
              </template>


            </ul>
          </div>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

const CustomerDetails = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerDetails'))
const AllBranches = defineAsyncComponent(() =>
  import('@/components/Customer/Branches/AllBranches'))
const AllCustomerContacts = defineAsyncComponent(() =>
  import('@/components/Customer/CustomerContacts/AllCustomerContacts'))
const AllOrderHistory = defineAsyncComponent(() =>
  import('@/components/Customer/OrderHistory/AllOrderHistory'))
const InvoiceList = defineAsyncComponent(() =>
  import('@/components/Invoices/InvoiceList'))

export default {
  name: "Customer",
  components: {
    CustomerDetails,
    AllBranches,
    AllCustomerContacts,
    AllOrderHistory,
    InvoiceList,
  },
  data: () => ({}),
  mounted() {
    this.$store.dispatch('clearCustomer')
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('getCustomer', this.$route.params.customerId)
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
    })
  },
  methods: {
  goBack(){
    this.$router.go(-1)
  },
  viewPrices() {
    this.$router.push('/customers/' + this.customer.id + '/prices')
  },
  viewOrders() {
    this.$router.push('/customers/' + this.customer.id + '/orders')
  },
  deleteCustomer(customerId){
    if(confirm('Vai esi drošs?')){
      this.$Progress.start()
      this.$store.dispatch('deleteCustomer', customerId)
    }
  }
  }
}
</script>

<style>

</style>